import React from 'react';
import {connect} from "react-redux";
import {withLocalize, Translate} from "react-localize-redux";
import {
    Flex,
    Divider,
    Input,
    Box,
    Group,
    NavLink,
    Link,
    ButtonOutline,
    Text,
    Caps,
    Image,
    Subhead,
    Container
} from 'rebass';
import {NavLink as NLink} from 'react-router-dom';
import {Spring, animated} from 'react-spring';
import Popup from "reactjs-popup";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import {Icon} from 'react-icons-kit';
import {
    plus,
    minus,
    sortUp,
    check,
    facebookSquare,
    instagram,
    youtube,
    twitterSquare,
    googlePlusSquare,
    linkedin,
    home,
    phone,
    envelopeO
} from 'react-icons-kit/fa/';
import {globe} from 'react-icons-kit/iconic/';

import {changeStringsLanguage} from 'actions/strings';
import {subscribeNewsletter} from 'thunk/website/website';

import icon from 'images/icon-small.png';
import fedr from 'images/fedr.png';
import p2020 from 'images/p2020.png';
import n2020 from 'images/n2020.png';

import 'components/Footer/Footer.css'

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleQuickLinks: false,
            showPrivacyPolicyModal: false,
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            inputEmail: "",
        }
        this.toggleQuickLinks = this.toggleQuickLinks.bind(this);
        this.togglePrivacyPolicy = this.togglePrivacyPolicy.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.updateInputEmail = this.updateInputEmail.bind(this);
    }

    updateInputEmail(evt) {
        this.setState({
            inputEmail: evt.target.value
        });
    }

    toggleQuickLinks() {
        this.setState({
            toggleQuickLinks: !this.state.toggleQuickLinks
        });
    }

    togglePrivacyPolicy() {
        this.setState({
            showPrivacyPolicyModal: !this.state.showPrivacyPolicyModal
        });
    }

    updateWindowDimensions() {
        this.setState({windowWidth: window.innerWidth, windowHeight: window.innerHeight});
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    render() {
        const {languages, translate, strings, setActiveLanguage, changeStringsLanguage, activeLanguage, subscribeNewsletter, website} = this.props;
        const {inputEmail, windowWidth} = this.state;
        return (
            <Flex is="footer" alignItems="stretch" pt={3} bg="#212121" flexDirection="column">
                <Box px="10%">
                    <NavLink>
                        <Group mb={1} onClick={this.toggleQuickLinks} color="#FAFAFA">
                            {this.state.toggleQuickLinks ? <Icon icon={minus}/> : <Icon icon={plus}/>}
                            <Text ml={1} color="#FAFAFA" fontWeight='bold' children={translate("quickLinks")}/>
                        </Group>
                    </NavLink>
                    <Spring native force from={{height: 0}} to={{height: this.state.toggleQuickLinks ? 'auto' : 0}}>
                        {props => (
                            <animated.div className="togglable-div" style={props}>
                                <Flex flexWrap='wrap' justifyContent={["space-around", "space-around", "space-around", "space-around"]}>
                                    <Box p={3}>
                                        <Link href={"https://storage.googleapis.com/fixpacos/catalogo-" + (activeLanguage !== undefined ? activeLanguage.code : "pt") + ".pdf"}
                                              target="_blank"
                                              style={{textDecoration: "none"}}>
                                            <Text
                                                textAlign='center'
                                                fontWeight='bold'
                                                color="#FAFAFA"
                                                children={translate('catalogue')}
                                            />
                                        </Link>
                                    </Box>
                                    <Box p={3}>
                                        <Link href={"https://storage.googleapis.com/fixpacos/revista-" + (activeLanguage !== undefined ? activeLanguage.code : "pt") + ".pdf"}
                                              target="_blank"
                                              style={{textDecoration: "none"}}>
                                            <Text
                                                textAlign='center'
                                                fontWeight='bold'
                                                color="#FAFAFA"
                                                children={translate('magazine')}
                                            />
                                        </Link>
                                    </Box>
                                </Flex>
                            </animated.div>
                        )}
                    </Spring>
                    <Divider className="divider"/>
                </Box>
                <Box px="10%">
                    <Flex flexWrap='wrap' alignItems="center" justifyContent={["space-around", "space-around", "space-around", "space-between"]}>
                        <Box p={3}>
                            <Text
                                textAlign={["center", "left"]}
                                fontWeight='bold'
                                color="#FAFAFA"
                                children={translate("company") + " (" + translate("headquarters") + ")"}
                            />
                            <Flex flexWrap='wrap' flexDirection="column" alignItems={["center", "flex-start"]}>
                                <Flex flexWrap='wrap' justifyContent="center" color="#BDBDBD" mt={3}>
                                    {windowWidth >= 368 && <Icon icon={home}/>}
                                    <Text ml={2} fontWeight='normal' fontSize="0.8em" textAlign={["center", "left"]} children={translate("hqAddress1")}/>
                                </Flex>
                                <Group color="#BDBDBD">
                                    <Text style={{marginLeft: "24px"}} fontWeight='normal' fontSize="0.8em" textAlign={["center", "left"]} children={translate("hqAddress2")}/>
                                </Group>
                                <Group color="#BDBDBD" mt={2}>
                                    <Icon icon={phone}/>
                                    <Text ml={2} fontWeight='normal' fontSize="0.8em" textAlign={["center", "left"]} children={translate("mainPhone")}/>
                                </Group>
                                <Group color="#BDBDBD" mt={2}>
                                    <Icon icon={envelopeO}/>
                                    <Link href={"mailto:" + translate("mainEmail")} className="mail-link" color="#FAFAFA">
                                        <Text ml={2} color="#FAFAFA" fontWeight='normal' fontSize="0.8em" textAlign={["center", "left"]} children={translate("mainEmail")}/>
                                    </Link>
                                </Group>
                            </Flex>
                        </Box>
                        <Box p={3}>
                            <Text
                                textAlign={["center", "left"]}
                                fontWeight='bold'
                                color="#FAFAFA"
                                children={translate("customerSupport")}
                            />
                            <Flex flexWrap='wrap' flexDirection="column">
                                <NLink to="/about" className="link">
                                    <Text color="#BDBDBD" fontWeight='normal' fontSize="0.8em" textAlign={["center", "left"]} children={translate("whoWeAre")} mt={2}/>
                                </NLink>
                                <NLink to="/contact" className="link">
                                    <Text color="#BDBDBD" fontWeight='normal' fontSize="0.8em" textAlign={["center", "left"]} children={translate("contact")} mt={1}/>
                                </NLink>
                                <NLink to="/contact" className="link">
                                    <Text color="#BDBDBD" fontWeight='normal' fontSize="0.8em" textAlign={["center", "left"]} children={
                                        activeLanguage !== undefined && activeLanguage.code === "en" ?
                                            translate("brandName") + " " + translate("stores")
                                            : translate("stores") + " " + translate("brandName")
                                    } mt={1}/>
                                </NLink>
                                <Link href="#!" onClick={this.togglePrivacyPolicy} className="link">
                                    <Text color="#BDBDBD" fontWeight='normal' fontSize="0.8em" textAlign={["center", "left"]} children={translate("privacyPolicy")}
                                          mt={1}/>
                                </Link>
                                <Rodal width={1000} visible={this.state.showPrivacyPolicyModal}
                                       onClose={this.togglePrivacyPolicy} animation="fade" duration={500}>
                                    <Container>
                                        <Subhead children={translate("privacyPolicyTitle")}/>
                                        {translate("privacyPolicyText").split("\n").map((p, i) => (
                                            <Text key={i} mt={3} dangerouslySetInnerHTML={{__html: p}}/>
                                        ))}
                                    </Container>
                                </Rodal>
                            </Flex>
                        </Box>
                        <Flex flexDirection="column" alignItems="flex-end" justifyContent="flex-end">
                            <Box>
                                <Text fontSize="0.9em" color="#FAFAFA" textAlign='center'>
                                    <Translate id="signUpText"/>
                                </Text>
                                <Flex py={2} flexWrap='wrap' justifyContent="center">
                                    <Box p={1} width={[1, 2 / 3]}>
                                        <Input
                                            placeholder={translate("newsletterInput")}
                                            bg="#FAFAFA"
                                            width="100%"
                                            borderColor="#98C221"
                                            borderRadius={5}
                                            value={inputEmail}
                                            type="email"
                                            onChange={evt => this.updateInputEmail(evt)}
                                        />
                                        {website.newsletter.invalid && <Text fontSize="0.9em" color="red" children={translate("invalidEmail")}/>}
                                        {website.newsletter.error && <Text fontSize="0.9em" color="red" children={translate("error")}/>}
                                        {website.newsletter.success && <Text fontSize="0.9em" color="green" children={translate("subscribeSuccess")}/>}
                                    </Box>
                                    <Box p={1} width={[1, 1 / 3]}>
                                        <Flex justifyContent="center">
                                            <ButtonOutline
                                                lineHeight={1.7}
                                                color="#98C221"
                                                m={0}
                                                className="subscribe-button"
                                                children={translate("newsletterButton")}
                                                onClick={() => {if (subscribeNewsletter(inputEmail)) {this.setState({inputEmail: ""});}}}
                                            />
                                        </Flex>
                                    </Box>
                                </Flex>
                            </Box>
                            <Box>
                                <Flex flexWrap='wrap' alignItems="center" justifyContent="center">
                                    <Link href="https://storage.googleapis.com/fixpacos/projeto.pdf" target="_blank">
                                        <Box m={3} style={{maxWidth: "140px"}}><Image src={n2020}/></Box>
                                    </Link>
                                    <Link href="https://storage.googleapis.com/fixpacos/projeto.pdf" target="_blank">
                                        <Box m={3} style={{maxWidth: "110px"}}><Image src={p2020}/></Box>
                                    </Link>
                                    <Link href="https://storage.googleapis.com/fixpacos/projeto.pdf" target="_blank">
                                        <Box m={3} style={{maxWidth: "110px"}}><Image src={fedr}/></Box>
                                    </Link>
                                </Flex>
                            </Box>
                        </Flex>
                    </Flex>
                    <Divider className="divider"/>
                </Box>
                <Flex px="10%" flexDirection={["column", "column", "column", "row"]} justifyContent="space-between">
                    <Box alignSelf="center" m={2}>
                        <Group>
                            {strings.pages.footer.social.facebookLink !== "" ?
                                <NavLink className="social-link" target="_blank"
                                         href={strings.pages.footer.social.facebookLink}>
                                    <Icon size='25' icon={facebookSquare}/>
                                </NavLink>
                                : null}
                            {strings.pages.footer.social.instagramLink !== "" ?
                                <NavLink className="social-link" target="_blank"
                                         href={strings.pages.footer.social.instagramLink}>
                                    <Icon size='25' icon={instagram}/>
                                </NavLink>
                                : null}
                            {strings.pages.footer.social.googlePlusLink !== "" ?
                                <NavLink className="social-link" target="_blank"
                                         href={strings.pages.footer.social.googlePlusLink}>
                                    <Icon size='25' icon={googlePlusSquare}/>
                                </NavLink>
                                : null}
                            {strings.pages.footer.social.twitterLink !== "" ?
                                <NavLink className="social-link" target="_blank"
                                         href={strings.pages.footer.social.twitterLink}>
                                    <Icon size='25' icon={twitterSquare}/>
                                </NavLink>
                                : null}
                            {strings.pages.footer.social.youtubeLink !== "" ?
                                <NavLink className="social-link" target="_blank"
                                         href={strings.pages.footer.social.youtubeLink}>
                                    <Icon size='25' icon={youtube}/>
                                </NavLink>
                                : null}
                            {strings.pages.footer.social.linkedInLink !== "" ?
                                <NavLink className="social-link" target="_blank"
                                         href={strings.pages.footer.social.linkedInLink}>
                                    <Icon size='25' icon={linkedin}/>
                                </NavLink>
                                : null}
                            <Box m={2} style={{borderRight: '1px solid rgba(255,255,255,.1)', padding: '0.5em'}}/>
                            <Popup ref="langPopup" trigger={
                                <NavLink className="social-link">
                                    <Icon size='25' icon={globe}/>
                                    <Text mx={2}
                                          children={activeLanguage !== undefined ? activeLanguage.code.toUpperCase() : ""}/>
                                    <Icon icon={sortUp}/>
                                </NavLink>
                            }
                                   position="top center"
                                   closeOnDocumentClick contentStyle={{
                                borderRadius: "10px",
                            }}>
                                <Flex flexWrap='wrap' flexDirection="column" alignItems="center"
                                      justifyContent="center">
                                    <Caps color="#616161" children={translate("changeLanguage")}/>
                                    <Flex flexWrap='wrap' alignItems="center" justifyContent="flex-start" ml={3}>
                                        {languages.map((lang) => (
                                            <NavLink key={lang.code} mx={2} onClick={() => {
                                                setActiveLanguage(lang.code);
                                                changeStringsLanguage(lang.code);
                                                localStorage.setItem("languageCode", lang.code);
                                                this.refs.langPopup.closePopup();
                                            }}>
                                                <Flex>
                                                    <Box color={lang.active ? "#98C221" : "#212121"}>
                                                        <Icon icon={check}/></Box>
                                                    <Text mx={2} color={lang.active ? "#98C221" : "#212121"}
                                                          children={translate(lang.code)}/>
                                                </Flex>
                                            </NavLink>
                                        ))}
                                    </Flex>
                                </Flex>
                            </Popup>
                        </Group>
                    </Box>
                    <Box alignSelf="center">
                        <Flex flexWrap='wrap' justifyContent="center">
                            <Text mt={1} textAlign="center" fontSize="0.9em" color="#FAFAFA">Copyright © 2018 Fixpaços,
                                SA.</Text>
                            <Image src={icon} mx={3}/>
                        </Flex>
                    </Box>
                </Flex>
            </Flex>
        );
    }
}

const mapStateToProps = state => {
    return {
        strings: state.strings,
        website: state.website
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeStringsLanguage: lang => dispatch(changeStringsLanguage(lang)),
        subscribeNewsletter: email => dispatch(subscribeNewsletter(email)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(Footer));