import React, {Component} from 'react';
import {Heading, Divider, Container, Flex, Box, Card, Text, Subhead, Link, Input, Textarea, Button} from 'rebass';
import {Translate} from "react-localize-redux";
import {Helmet} from "react-helmet";

import {ContactMap, MapItem} from "components/ContactMap";
import TitleBox from "components/TitleBox";

import {submitContactForm} from 'thunk/website/website';

import {
    home,
    phone,
    envelopeO
} from 'react-icons-kit/fa/';

import 'pages/Contact/Contact.css';
import {Icon} from "react-icons-kit";
import connect from "react-redux/es/connect/connect";

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibility: "hide",
            center: {
                lat: 41.274335,
                lng: -8.359872
            },
            zoom: 8,
            inputName: "",
            inputEmail: "",
            inputMessage: ""
        }
        this.updateInputName = this.updateInputName.bind(this);
        this.updateInputEmail = this.updateInputEmail.bind(this);
        this.updateInputMessage = this.updateInputMessage.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({visibility: "show"})
    }

    updateInputName(evt) {
        this.setState({
            inputName: evt.target.value
        });
    }

    updateInputEmail(evt) {
        this.setState({
            inputEmail: evt.target.value
        });
    }

    updateInputMessage(evt) {
        this.setState({
            inputMessage: evt.target.value
        });
    }

    render() {
        const {translate, submitContactForm, website} = this.props;
        const {inputName, inputEmail, inputMessage} = this.state;
        return (
            <Flex flexDirection="column" alignItems="center" bg='#FAFAFA'>
                <Helmet>
                    <title>{translate("pageTitle") + " - " + translate("contact")}</title>
                    <meta name="description" content={translate("contactPageDescription")}/>
                    <link rel="canonical" href={window.pageURL + this.props.location.pathname}/>
                    <link rel="alternate" hrefLang="pt" href={window.pageURL + "/pt" + this.props.location.pathname}/>
                    <link rel="alternate" hrefLang="en" href={window.pageURL + "/en" + this.props.location.pathname}/>
                    <link rel="alternate" hrefLang="fr" href={window.pageURL + "/fr" + this.props.location.pathname}/>
                    <link rel="alternate" hrefLang="x-default" href={window.pageURL + this.props.location.pathname}/>
                </Helmet>
                <Box className="header"/>
                <Flex width="80%" mt={4} mb={4}>
                    <TitleBox pose={this.state.visibility}>
                        <Container>
                            <Heading color="#424242" fontSize={["5vmax", "4vmax", "3vmax"]} textAlign="left">
                                <Translate id="contact"/></Heading>
                            <Divider borderColor='#98C221'/>
                        </Container>
                    </TitleBox>
                </Flex>
                <Flex flexDirection="column" alignItems="space-around" justifyContent="center" flexWrap="wrap"
                      width="100%" my={5}>
                    <ContactMap center={this.state.center}>
                        <MapItem lat={41.274335} lng={-8.359872} text={translate("pacos")}/>
                        <MapItem lat={37.742292} lng={-25.674947} text={translate("azores")}/>
                        <MapItem lat={49.6606842} lng={5.9202825} text={translate("luxembourg")}/>
                    </ContactMap>
                    <Flex flexDirection={["column", "column", "row", "row"]}
                          alignItems={["center", "center", "stretch", "stretch"]} justifyContent="center" mx={4}>
                        <Card className="info-card" mx={2} my={3} width="80%"
                              onClick={() => this.setState({center: {lat: 41.274335, lng: -8.359872}})}>
                            <Subhead ml={2}><Translate id="headquarters"/></Subhead>
                            <Flex justifyContent="flex-start" color="#212121" mx={2} mt={3}>
                                <Icon icon={home} size={30}/>
                                <Flex flexDirection="column" justifyContent="center">
                                    <Text ml={2} fontWeight='normal' fontSize="1em" children={translate("hqAddress1")}/>
                                    <Text ml={2} fontWeight='normal' fontSize="1em" children={translate("hqAddress2")}/>
                                </Flex>
                            </Flex>
                            <Flex color="#212121" alignItems="center" mx={2} mt={2}>
                                <Icon icon={phone} size={30}/>
                                <Text ml={2} fontWeight='normal' fontSize="1em" textAlign={["center", "left"]}
                                      children={translate("mainPhone")}/>
                            </Flex>
                            <Flex color="#212121" alignItems="center" mx={2} mt={2}>
                                <Icon icon={envelopeO} size={30}/>
                                <Link href={"mailto:" + translate("mainEmail")} className="mail-link" color="#212121">
                                    <Text ml={2} fontWeight='normal' fontSize="1em" textAlign={["center", "left"]}
                                          children={translate("mainEmail")}/>
                                </Link>
                            </Flex>
                        </Card>
                        <Card className="info-card" mx={2} my={3} width="80%"
                              onClick={() => this.setState({center: {lat: 37.742292, lng: -25.674947}})}>
                            <Subhead ml={2}><Translate id="azores"/></Subhead>
                            <Flex justifyContent="flex-start" color="#212121" mx={2} mt={3}>
                                <Icon icon={home} size={30}/>
                                <Flex flexDirection="column" justifyContent="center">
                                    <Text ml={2} fontWeight='normal' fontSize="1em"
                                          children={"Zona Comercial dos Valados"}/>
                                    <Text ml={2} fontWeight='normal' fontSize="1em"
                                          children={"Rua Eduardo Soares de Albergaria, 27, Lote 10"}/>
                                    <Text ml={2} fontWeight='normal' fontSize="1em"
                                          children={"9500-681 Ponta Delgada"}/>
                                </Flex>
                            </Flex>
                            <Flex color="#212121" alignItems="center" mx={2} mt={2}>
                                <Icon icon={phone} size={30}/>
                                <Text ml={2} fontWeight='normal' fontSize="1em" textAlign={["center", "left"]}
                                      children={"+351 296 684 183"}/>
                            </Flex>
                            <Flex color="#212121" alignItems="center" mx={2} mt={2}>
                                <Icon icon={envelopeO} size={30}/>
                                <Link href={"mailto:acores@fixpacos.com"} className="mail-link" color="#212121">
                                    <Text ml={2} fontWeight='normal' fontSize="1em" textAlign={["center", "left"]}
                                          children={"acores@fixpacos.com"}/>
                                </Link>
                            </Flex>
                        </Card>
                        <Card className="info-card" mx={2} my={3} width="80%"
                              onClick={() => this.setState({center: {lat: 49.6606842, lng: 5.9202825}})}>
                            <Subhead ml={2}><Translate id="luxembourg"/></Subhead>
                            <Flex justifyContent="flex-start" color="#212121" mx={2} mt={3}>
                                <Icon icon={home} size={30}/>
                                <Flex flexDirection="column" justifyContent="center">
                                    <Text ml={2} fontWeight='normal' fontSize="1em" children={"52 B, Rue de Koerich"}/>
                                    <Text ml={2} fontWeight='normal' fontSize="1em" children={"L-8437 Steinfort"}/>
                                    <Text ml={2} fontWeight='normal' fontSize="1em" children={translate("luxembourg")}/>
                                </Flex>
                            </Flex>
                            <Flex color="#212121" alignItems="center" mx={2} mt={2}>
                                <Icon icon={phone} size={30}/>
                                <Text ml={2} fontWeight='normal' fontSize="1em" textAlign={["center", "left"]}
                                      children={"+352 28 79 84 26"}/>
                            </Flex>
                            <Flex color="#212121" alignItems="center" mx={2} mt={2}>
                                <Icon icon={envelopeO} size={30}/>
                                <Link href={"mailto:general@fixpacos.com"} className="mail-link" color="#212121">
                                    <Text ml={2} fontWeight='normal' fontSize="1em" textAlign={["center", "left"]}
                                          children={"general@fixpacos.com"}/>
                                </Link>
                            </Flex>
                        </Card>
                    </Flex>
                </Flex>
                <Box width="100%" bg="#212121">
                    <Flex flexDirection="column" alignItems="center" justifyContent="center" my={5}>
                        <Heading color="white" children={translate("contactUs")}/>
                        <Flex flexWrap="wrap" alignItems="space-around" justifyContent="center" width="80%" mt={5}>
                            <Box width={[1, 1, 1 / 2, 1 / 2]}>
                                <Flex flexDirection="column" alignItems="flex-start" justifyContent="center"
                                      ml={[0, 0, 4, 6]}>
                                    <Subhead fontSize="1.2em" color="white" children={translate("name")} mb={1}/>
                                    <Input
                                        placeholder={translate("name")}
                                        bg="#FAFAFA"
                                        borderColor="#98C221"
                                        borderRadius={5}
                                        width="100%"
                                        mb={3}
                                        value={website.contactForm.success ? "" : inputName}
                                        onChange={evt => this.updateInputName(evt)}
                                    />
                                    <Subhead fontSize="1.2em" color="white" children={translate("email")} mb={1}/>
                                    <Input
                                        placeholder={translate("email")}
                                        bg="#FAFAFA"
                                        borderColor="#98C221"
                                        borderRadius={5}
                                        width="100%"
                                        mb={3}
                                        value={website.contactForm.success ? "" : inputEmail}
                                        onChange={evt => this.updateInputEmail(evt)}
                                    />
                                </Flex>
                            </Box>
                            <Box width={[1, 1, 1 / 2, 1 / 2]}>
                                <Flex flexDirection="column" alignItems="flex-start" justifyContent="flex-start"
                                      ml={[0, 0, 4, 6]}>
                                    <Subhead fontSize="1.2em" color="white" children={translate("message")} mb={1}/>
                                    <Textarea
                                        placeholder={translate("message")}
                                        bg="#FAFAFA"
                                        borderColor="#98C221"
                                        borderRadius={5}
                                        width="100%"
                                        rows="5"
                                        mb={3}
                                        value={website.contactForm.success ? "" : inputMessage}
                                        onChange={evt => this.updateInputMessage(evt)}
                                    />
                                </Flex>
                            </Box>
                        </Flex>
                        <Flex flexDirection="column" alignItems="center" justifyContent="center">
                            {website.contactForm.emptyName &&
                            <Text fontSize="0.9em" color="red" children={translate("emptyName")}/>}
                            {website.contactForm.emptyEmail &&
                            <Text fontSize="0.9em" color="red" children={translate("emptyEmail")}/>}
                            {website.contactForm.emptyMessage &&
                            <Text fontSize="0.9em" color="red" children={translate("emptyMessage")}/>}
                            {website.contactForm.invalidEmail &&
                            <Text fontSize="0.9em" color="red" children={translate("invalidEmail")}/>}
                            {website.contactForm.error &&
                            <Text fontSize="0.9em" color="red" children={translate("error")}/>}
                            {website.contactForm.success &&
                            <Text fontSize="0.9em" color="green" children={translate("contactFormSuccess")}/>}
                            <Button
                                m={2}
                                fontSize='1em'
                                children={translate("submit")}
                                bg="#98C221"
                                color="black"
                                style={{cursor: "pointer"}}
                                onClick={() => submitContactForm(inputName, inputEmail, inputMessage)}
                            />
                        </Flex>
                    </Flex>
                </Box>
                <div style={{height: "30px", width: "100%", backgroundColor: "#98C221"}}/>
            </Flex>
        );
    }
}

const mapStateToProps = state => {
    return {
        website: state.website
    };
};

const mapDispatchToProps = dispatch => {
    return {
        submitContactForm: (name, email, message) => dispatch(submitContactForm(name, email, message)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);