import { ACTION_SUBSCRIBE_NEWSLETTER, ACTION_SUBMIT_CONTACT_FORM } from 'constants/actions';

export const subscribeNewsletterAction = (status, data) => ({
    type: ACTION_SUBSCRIBE_NEWSLETTER,
    status: status,
    payload: data
});

export const submitContactFormAction = (status, data) => ({
    type: ACTION_SUBMIT_CONTACT_FORM,
    status: status,
    payload: data
});
