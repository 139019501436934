import React, {Component} from 'react';
import {
    Flex,
    Heading,
    Divider,
    Container,
    Subhead,
    Text,
    Image,
    Box
} from 'rebass';
import {Translate} from "react-localize-redux";
import {Player, LoadingSpinner, BigPlayButton} from 'video-react';
import {Helmet} from "react-helmet";
import "video-react/dist/video-react.css";

import TitleBox from 'components/TitleBox';

import videoPoster from "images/video-poster.jpg";
import pmeE2015 from 'images/pme-e-2015.png';
import pmeE2016 from 'images/pme-e-2016.png';
import pmeL2013 from 'images/pme-l-2013.png';
import pmeL2014 from 'images/pme-l-2014.png';
import pmeL2015 from 'images/pme-l-2015.png';
import pmeL2016 from 'images/pme-l-2016.png';

import 'pages/AboutUs/AboutUs.css';


class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibility: "hide"
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({visibility: "show"})
    }

    render() {
        const {translate} = this.props;
        return (
            <Flex flexDirection="column" alignItems="center" bg='#FAFAFA' style={{overflow: "hidden"}}>
                <Helmet>
                    <title>{translate("pageTitle") + " - " + translate("aboutUs")}</title>
                    <meta name="description" content={translate("whoWeAreText").split("\n")[0]}/>
                    <link rel="canonical" href={window.pageURL + this.props.location.pathname}/>
                    <link rel="alternate" hrefLang="pt" href={window.pageURL + "/pt" + this.props.location.pathname}/>
                    <link rel="alternate" hrefLang="en" href={window.pageURL + "/en" + this.props.location.pathname}/>
                    <link rel="alternate" hrefLang="fr" href={window.pageURL + "/fr" + this.props.location.pathname}/>
                    <link rel="alternate" hrefLang="x-default" href={window.pageURL + this.props.location.pathname}/>
                </Helmet>
                <Box className="header"/>
                <Flex width="80%" mt={4} mb={4}>
                    <TitleBox pose={this.state.visibility}>
                        <Container>
                            <Heading color="#424242" fontSize={["5vmax", "4vmax", "3vmax"]} textAlign="left">
                                <Translate id="aboutUs"/></Heading>
                            <Divider borderColor='#98C221'/>
                        </Container>
                    </TitleBox>
                </Flex>
                <Flex justifyContent="center" alignItems="center">
                    <Flex flexDirection="column" alignItems="center"
                          justifyContent="flex-start">
                        <Box mb={5} mt={5} className="text-container">
                            <Flex flexWrap='wrap' alignItems="center">
                                <Box width={[1, 1, 1, 1 / 2]}>
                                    <Flex flexDirection="column">
                                        <Flex flexWrap="wrap" alignItems="center">
                                            <Subhead color="#678B1B" textAlign='left'
                                                     fontSize={["1.8em", "1.8em", "2em", "2em"]} mr={2}>
                                                <Translate id="whoWeAreTitle"/>
                                                {translate("whoWeAreSubtitle") !== "" &&
                                                <span style={{color: "#212121", marginLeft: "6px"}}><Translate
                                                    id="whoWeAreSubtitle"/></span>
                                                }
                                            </Subhead>
                                        </Flex>
                                        {translate("whoWeAreText").split("\n").map((p, i) => (
                                            <Text key={i} mt={3} dangerouslySetInnerHTML={{__html: p}}/>
                                        ))}
                                    </Flex>
                                </Box>
                                <Box width={[1, 1, 1, 1 / 2]}>
                                    <Box m={4} className="video-card">
                                        <Player
                                            ref="videoPlayer"
                                            poster={videoPoster}
                                            playsInline
                                            className="video"
                                            src="https://storage.googleapis.com/fixpacos/fixpacos2018.mp4">
                                            <BigPlayButton position="center"/>
                                            <LoadingSpinner/>
                                        </Player>
                                    </Box>
                                </Box>
                            </Flex>
                        </Box>
                        <Box mb={4} mt={5} className="text-container">
                            <Flex flexWrap="wrap" alignItems="center" justifyContent="flex-start">
                                <Subhead color="#678B1B" textAlign='left' fontSize={["1.8em", "1.8em", "2em", "2em"]}
                                         mr={2}>
                                    <Translate id="acknowledgmentsTitle"/>
                                    {translate("acknowledgmentsSubtitle") !== "" &&
                                    <span style={{color: "#212121", marginLeft: "6px"}}><Translate
                                        id="acknowledgmentsSubtitle"/></span>
                                    }
                                </Subhead>
                            </Flex>
                            {translate("acknowledgmentsText").split("\n").map((p, i) => (
                                <Text key={i} mt={3} dangerouslySetInnerHTML={{__html: p}}/>
                            ))}
                            <Flex flexWrap="wrap" alignItems="center" justifyContent="space-around" my={4}>
                                <Box m={2} style={{maxWidth: "120px"}}><Image src={pmeE2015}/></Box>
                                <Box m={2} style={{maxWidth: "120px"}}><Image src={pmeE2016}/></Box>
                                <Box m={2} style={{maxWidth: "120px"}}><Image src={pmeL2013}/></Box>
                                <Box m={2} style={{maxWidth: "120px"}}><Image src={pmeL2014}/></Box>
                                <Box m={2} style={{maxWidth: "120px"}}><Image src={pmeL2015}/></Box>
                                <Box m={2} style={{maxWidth: "120px"}}><Image src={pmeL2016}/></Box>
                            </Flex>
                        </Box>
                        <Flex bg="#212121" alignItems="center" flexDirection="column">
                            <Box mb={5} mt={6} className="text-container">
                                <Flex flexWrap="wrap" alignItems="center" justifyContent="flex-start">
                                    <Subhead color="#678B1B" textAlign='left'
                                             fontSize={["1.8em", "1.8em", "2em", "2em"]} mr={2}>
                                        <Translate id="missionTitle"/>
                                        {translate("missionSubtitle") !== "" &&
                                        <span style={{color: "white", marginLeft: "6px"}}><Translate
                                            id="missionSubtitle"/></span>
                                        }
                                    </Subhead>
                                </Flex>
                                {translate("missionText").split("\n").map((p, i) => (
                                    <Text color="white" key={i} mt={3} dangerouslySetInnerHTML={{__html: p}}/>
                                ))}
                            </Box>
                            <Box mb={5} mt={5} className="text-container">
                                <Flex flexWrap="wrap" alignItems="center" justifyContent="flex-start">
                                    <Subhead color="#678B1B" textAlign='left'
                                             fontSize={["1.8em", "1.8em", "2em", "2em"]} mr={2}>
                                        <Translate id="valuesTitle"/>
                                        {translate("valuesSubtitle") !== "" &&
                                        <span style={{color: "white", marginLeft: "6px"}}><Translate
                                            id="valuesSubtitle"/></span>
                                        }
                                    </Subhead>
                                </Flex>
                                {translate("valuesText").split("\n").map((p, i) => (
                                    <Text color="white" key={i} mt={3} dangerouslySetInnerHTML={{__html: p}}/>
                                ))}
                            </Box>
                            <Box mb={5} mt={5} className="text-container">
                                <Flex flexWrap="wrap" alignItems="center" justifyContent="flex-start">
                                    <Subhead color="#678B1B" textAlign='left'
                                             fontSize={["1.8em", "1.8em", "2em", "2em"]} mr={2}>
                                        <Translate id="visionTitle"/>
                                        {translate("visionSubtitle") !== "" &&
                                        <span style={{color: "white", marginLeft: "6px"}}><Translate
                                            id="visionSubtitle"/></span>
                                        }
                                    </Subhead>
                                </Flex>
                                {translate("visionText").split("\n").map((p, i) => (
                                    <Text color="white" key={i} mt={3} dangerouslySetInnerHTML={{__html: p}}/>
                                ))}
                            </Box>
                        </Flex>
                        <Box mb={5} mt={5} className="text-container">
                            <Flex flexWrap="wrap" alignItems="center" justifyContent="flex-start">
                                <Subhead color="#678B1B" textAlign='left' fontSize={["1.8em", "1.8em", "2em", "2em"]}
                                         mr={2}>
                                    <Translate id="commitmentTitle"/>
                                    {translate("commitmentSubtitle") !== "" &&
                                    <span style={{color: "#212121", marginLeft: "6px"}}><Translate
                                        id="commitmentSubtitle"/></span>
                                    }
                                </Subhead>
                            </Flex>
                            {translate("commitmentText").split("\n").map((p, i) => (
                                <Text key={i} mt={3} dangerouslySetInnerHTML={{__html: p}}/>
                            ))}
                        </Box>
                        <Box mb={5} mt={5} className="text-container">
                            <Flex flexWrap="wrap" alignItems="center" justifyContent="flex-start">
                                <Subhead color="#678B1B" textAlign='left' fontSize={["1.8em", "1.8em", "2em", "2em"]}
                                         mr={2}>
                                    <Translate id="teamTitle"/>
                                    {translate("teamSubtitle") !== "" &&
                                    <span style={{color: "#212121", marginLeft: "6px"}}><Translate
                                        id="teamSubtitle"/></span>
                                    }
                                </Subhead>
                            </Flex>
                            {translate("teamText").split("\n").map((p, i) => (
                                <Text key={i} mt={3} dangerouslySetInnerHTML={{__html: p}}/>
                            ))}
                        </Box>
                    </Flex>
                </Flex>
            </Flex>
        );
    }
}

export default AboutUs;