import React, {Component} from 'react';
import { compose, withProps } from "recompose";
import {withScriptjs, withGoogleMap, GoogleMap, Marker} from "react-google-maps";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import {Card, Text} from "rebass";

import 'components/ContactMap/ContactMap.css';

class MapItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        }
        this.toggleOpen = this.toggleOpen.bind(this);
    }

    toggleOpen() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return (
            <Marker position={{ lat: this.props.lat, lng: this.props.lng }}
                    onClick={this.toggleOpen}
                    icon={this.props.icon}>
                {this.state.isOpen && <InfoBox
                    onCloseClick={this.toggleOpen}
                    options={{ closeBoxURL: ``, enableEventPropagation: true }}>
                        <Card width={200} boxShadow={2} borderRadius={2} bg="#212121">
                            <Text color="white" fontSize="1.5em">{this.props.text}</Text>
                        </Card>
                </InfoBox>}
            </Marker>
        );
    }
}


class _ContactMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            zoom: 7
        }
    }
    render() {
        return (
            <GoogleMap
                defaultCenter={this.props.center}
                center={this.props.center}
                defaultZoom={this.state.zoom}>
                {this.props.children}
            </GoogleMap>
        );
    }
}

const ContactMap = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCdkWR6CWD2xNOL0UbMD0AjpjWh1uFG_Yg&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
)(_ContactMap)

export {ContactMap , MapItem}