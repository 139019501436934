import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withLocalize, Translate} from "react-localize-redux";
import {withRouter} from 'react-router-dom';
import {Link, NavLink} from 'react-router-dom';
import {Collapse, Navbar, Nav, NavItem} from 'reactstrap';

import 'components/Header/Header.css';
import 'components/Header/hamburgers.min.css';

import logoLight from 'images/logo-light.png';
import logoDark from 'images/logo-web.png';


class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            scrollHeight: 0,
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight
        };

        this.toggle = this.toggle.bind(this);
        this.close = this.close.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    handleScroll() {
        this.setState({
            scrollHeight: window.scrollY
        })
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    close() {
        this.setState({
            isOpen: false
        });
    }

    updateWindowDimensions() {
        this.setState({windowWidth: window.innerWidth, windowHeight: window.innerHeight});
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    render() {
        const transparentNavbar = this.state.scrollHeight <= 100 && !this.state.isOpen;
        const expanded = this.state.windowWidth < 768;
        const border = this.props.location.pathname === '/';
        const {menuItems} = this.props;
        return (
            <Navbar expand="md" fixed="top" className={transparentNavbar ? "navbar" + (border ? " bordered" : "") : "navbar-light"}>
                <Link to="/" className="navbar-brand">
                    <img src={transparentNavbar ? logoLight : logoDark} style={{maxWidth: expanded ? "180px" : "210px"}} alt="logo"/>
                </Link>
                {expanded ?
                    <button className={"hamburger hamburger--collapse" + (this.state.isOpen ? " is-active" : "")}
                            onClick={this.toggle} type="button">
                            <span className="hamburger-box">
                                <span className={"hamburger-inner " + (transparentNavbar ? "hamburger-light" : "hamburger-dark")}/>
                            </span>
                    </button>
                    : null}
                <Collapse isOpen={this.state.isOpen} navbar className="navbar-menu-items">
                    <Nav className="ml-auto" navbar>
                        { menuItems.map( route => (
                            <NavItem key={route.key} className={expanded ? "menu-item-expanded" : ""}><NavLink onClick={this.close} exact={route.path === '/'} to={route.path} className="menu-item"><Translate id={route.key}/></NavLink></NavItem>
                        ))}
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}

Header.propTypes = {
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired
        }).isRequired
    ).isRequired,
}

export default withLocalize(withRouter(Header));