import React, {Component} from 'react';
import {Flex, Heading, Divider, Container, Text, Box} from 'rebass';
import {Translate} from "react-localize-redux";
import { Icon } from 'react-icons-kit';
import {Helmet} from "react-helmet";

import TitleBox from 'components/TitleBox';
import {ic_search} from 'react-icons-kit/md/ic_search';

import 'pages/NotFound/NotFound.css';

class NotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibility: "hide"
        }
    }

    componentDidMount() {
        window.scrollTo(0,0);
        this.setState({visibility: "show"})
    }

    render() {
        const {translate} = this.props;
        return (
            <Flex flexDirection="column" alignItems="center" bg='#FAFAFA' style={{overflow: "hidden"}}>
                <Helmet>
                    <title>{translate("pageTitle")}</title>
                    <link rel="canonical" href={window.pageURL}/>
                </Helmet>
                <Box className="header"/>
                <Flex width="80%" mt={4} mb={4}>
                    <TitleBox pose={this.state.visibility}>
                        <Container>
                            <Heading color="#424242" fontSize={["5vmax","4vmax", "3vmax"]} textAlign="left">
                                <Translate id="notFoundTitle"/></Heading>
                            <Divider borderColor='#98C221'/>
                        </Container>
                    </TitleBox>
                </Flex>
                <Box my={3}>
                    <Icon size={220} icon={ic_search} />
                </Box>
                <Box my={5} mx={2} width={["80%","60%"]}>
                    <Text fontSize="1.3em" textAlign="center" color="#424242"><Translate id="notFoundText"/></Text>
                </Box>
            </Flex>
        );
    }
}

export default NotFound;