import enGlobalTranslations from './en.global.json';
import ptGlobalTranslations from './pt.global.json';
import frGlobalTranslations from './fr.global.json';

export default [
    {
        "lang": "en",
        "translations": enGlobalTranslations
    },
    {
        "lang": "pt",
        "translations": ptGlobalTranslations
    },
    {
        "lang": "fr",
        "translations": frGlobalTranslations
    }
];