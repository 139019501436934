import HomePage from 'pages/HomePage';
import AboutUs from 'pages/AboutUs';
import Catalogues from 'pages/Catalogues';
import Blog from 'pages/Blog';
import Contact from 'pages/Contact';

window.pageURL = "https://www.fixpacos.com";

export default [
    {
        path: "/",
        key: "home",
        component: HomePage
    },
    {
        path: "/about",
        key: "aboutUs",
        component: AboutUs
    },
    {
        path: "/catalogues",
        key: "catalogues",
        component: Catalogues
    },
    {
        path: "/blog",
        key: "blog",
        component: Blog
    },
    {
        path: "/contact",
        key: "contact",
        component: Contact
    }
]