import { combineReducers } from 'redux';
import { localizeReducer } from 'react-localize-redux';

import stringsReducer from './strings';
import websiteReducer from './website';

export default combineReducers({
    localize: localizeReducer,
    strings: stringsReducer,
    website: websiteReducer
})