import React, {Component} from 'react';
import {connect} from "react-redux";
import {Heading, Divider, Container, Link, Box, Flex, Image, Text, Subhead, Small} from 'rebass';
import {Translate} from "react-localize-redux";
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Rodal from 'rodal';
import {Helmet} from "react-helmet";
import 'rodal/lib/rodal.css';

import TitleBox from 'components/TitleBox';

import 'pages/Blog/Blog.css';

class Blog extends Component {
    constructor(props) {
        super(props);
        var indexes = [];
        for (var i = 0; i < props.news.length; i++) {
            indexes.push(false);
        }
        this.state = {
            titleVisibility: "hide",
            newsIndexes: indexes
        }

        this.toggleRodal = this.toggleRodal.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({titleVisibility: "show"})
    }

    toggleRodal(index) {
        const newsIndexes = this.state.newsIndexes;
        newsIndexes[index] = !newsIndexes[index];
        this.setState({newsIndexes});
    }

    render() {
        const {news, translate} = this.props;
        return (
            <Flex flexDirection="column" alignItems="center" bg='#FAFAFA'>
                <Helmet>
                    <title>{translate("pageTitle") + " - " + translate("blog")}</title>
                    <meta name="description" content={translate("blogTitle")}/>
                    <link rel="canonical" href={window.pageURL + this.props.location.pathname}/>
                    <link rel="alternate" hrefLang="pt" href={window.pageURL + "/pt" + this.props.location.pathname}/>
                    <link rel="alternate" hrefLang="en" href={window.pageURL + "/en" + this.props.location.pathname}/>
                    <link rel="alternate" hrefLang="fr" href={window.pageURL + "/fr" + this.props.location.pathname}/>
                    <link rel="alternate" hrefLang="x-default" href={window.pageURL + this.props.location.pathname}/>
                </Helmet>
                <Box className="header"/>
                <Flex width="80%" mt={4} mb={4}>
                    <TitleBox pose={this.state.titleVisibility}>
                        <Container>
                            <Heading color="#424242" fontSize={["5vmax", "4vmax", "3vmax"]} textAlign="left">
                                <Translate id="blog"/></Heading>
                            <Divider borderColor='#98C221'/>
                        </Container>
                    </TitleBox>
                </Flex>
                <Box my={4} mx={2} width={["80%", "60%"]}>
                    <Text fontSize="1.3em" textAlign="center" color="#424242"><Translate id="blogTitle"/></Text>
                </Box>
                <Flex flexDirection="column" alignItems="center" bg='#FAFAFA' mx={3}>
                    <VerticalTimeline>
                        {news.map((item, i) => (
                            <VerticalTimelineElement
                                key={"news-" + i}
                                className="timeline-element"
                                date={item.date}
                                iconStyle={{background: '#FAFAFA'}}>
                                <Link href="#!" onClick={() => this.toggleRodal(i)} className="news-card">
                                    <Flex alignItems="center" flexWrap="wrap" justifyContent="center">
                                        <Box width={[1, 1, 1, 1 / 2]} p={2}>
                                            <Image src={item.image}/>
                                        </Box>
                                        <Box width={[1, 1, 1, 1 / 2]}>
                                            <Text fontSize="1.2em" fontWeight="bold">{item.title}</Text>
                                        </Box>
                                    </Flex>
                                </Link>
                                <Rodal width={1000} visible={this.state.newsIndexes[i]}
                                       onClose={() => this.toggleRodal(i)} animation="rotate" duration={500}>
                                    <Container>
                                        <Subhead children={item.title}/>
                                        <Flex flexDirection={["column", "row"]} my={3}>
                                            <Box width={[1, 1, 1 / 2, 1 / 2]} p={3}>
                                                <Image src={item.image}/>
                                            </Box>
                                            <Box width={[1, 1, 1 / 2, 1 / 2]}>
                                                {item.text.split("\n").map((p, i) => (
                                                    <Text key={i} mt={3} dangerouslySetInnerHTML={{__html: p}}/>
                                                ))}
                                                {item.url !== undefined &&
                                                <Link href={item.url} target="_blank" mt={6}>
                                                    <Text textAlign="right" children={translate("checkSource")}/>
                                                </Link>
                                                }
                                            </Box>
                                        </Flex>
                                        <Small>{item.date}</Small>
                                    </Container>
                                </Rodal>
                            </VerticalTimelineElement>
                        ))}
                    </VerticalTimeline>
                </Flex>
            </Flex>
        );
    }
}

const mapStateToProps = state => {
    return {
        strings: state.strings,
        news: state.strings.pages.blog.news
    };
};

export default connect(mapStateToProps)(Blog);