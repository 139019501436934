import {ACTION_SUBSCRIBE_NEWSLETTER, ACTION_SUBMIT_CONTACT_FORM} from 'constants/actions';
import {STATUS_ERROR, STATUS_INVALID, STATUS_OK, STATUS_PROCESSING, STATUS_EMPTY_EMAIL, STATUS_EMPTY_MESSAGE, STATUS_EMPTY_NAME} from "constants/status";


const initState = {
    newsletter: {
        processing: false,
        error: false,
        invalid: false,
        success: false
    },
    contactForm: {
        processing: false,
        error: false,
        emptyName: false,
        emptyEmail: false,
        emptyMessage: false,
        success: false
    }
}

const websiteReducer = (state = initState, action) => {
    switch (action.type) {
        case ACTION_SUBSCRIBE_NEWSLETTER:
            return {
                ...state,
                newsletter: {
                    processing: action.status === STATUS_PROCESSING,
                    error: action.status === STATUS_ERROR,
                    invalid: action.status === STATUS_INVALID,
                    success: action.status === STATUS_OK
                }
            };
        case ACTION_SUBMIT_CONTACT_FORM:
            return {
                ...state,
                contactForm: {
                    processing: action.status === STATUS_PROCESSING,
                    error: action.status === STATUS_ERROR,
                    emptyName: action.status === STATUS_EMPTY_NAME,
                    emptyEmail: action.status === STATUS_EMPTY_EMAIL,
                    invalidEmail: action.status === STATUS_INVALID,
                    emptyMessage: action.status === STATUS_EMPTY_MESSAGE,
                    success: action.status === STATUS_OK
                }
            };
        default:
            return {
                ...state,
                contactForm: {
                    processing: false,
                    error: false,
                    emptyName: false,
                    emptyEmail: false,
                    invalidEmail: false,
                    emptyMessage: false,
                    success: false
                },
                newsletter: {
                    processing: false,
                    error: false,
                    invalid: false,
                    success: false
                }
            };
    }
};

export default websiteReducer;