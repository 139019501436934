import React, {Component} from "react";
import PropTypes from 'prop-types';
import {Flex, Text} from 'rebass';
import VisibilitySensor from 'react-visibility-sensor';

import 'components/Counter/circle.css'

class Counter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            counter: 0,
            stopAt: 100,
            percentage: 0,
            speed: 1,
            done: false
        }
        if (props.stopAt > 0) {
            this.state.stopAt = props.stopAt;
        }
        this.timeout = null;
        this.increment=this.increment.bind(this);
        this.onVisibilityChanged = this.onVisibilityChanged.bind(this);
    }

    onVisibilityChanged(isVisible) {
        if (isVisible && this.timeout == null) {
            this.timeout = setTimeout(this.increment, this.state.speed);
        }
    }

    increment() {
        if (this.state.counter < this.state.stopAt) {
            const c = this.state.counter + Math.ceil(1/this.state.speed);
            const s = 60 / (this.state.stopAt - c);
            const p = Math.ceil(c/this.state.stopAt*100)
            this.setState({
                counter: c,
                speed: s,
                percentage: p,
                done: c >= this.state.stopAt
            });
            this.timeout = setTimeout(this.increment, this.state.speed);
        }
    }

    render() {
        const hasText = this.props.text !== undefined;
        return (
            <VisibilitySensor partialVisibility onChange={this.onVisibilityChanged} >
                <Flex alignItems='center' justifyContent='center' flexDirection='column'>
                    <div className={"c100 big center green p"+this.state.percentage}>
                        <span>{this.state.counter}</span>
                        <div className="slice">
                            <div className="bar"></div>
                            <div className="fill"></div>
                        </div>
                    </div>
                    {hasText ?
                        <Text textAlign='center' color='white' m={30} fontSize={['3vmax','2.5vmax','2vmax','1.8vmax']} dangerouslySetInnerHTML={{__html: this.props.text.replace("{{.counter}}",this.state.counter)}}/>
                        :null}
                </Flex>
            </VisibilitySensor>
        );
    }
}

Counter.propTypes = {
    stopAt: PropTypes.number,
    text: PropTypes.string
}

export default Counter;