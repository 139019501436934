/* eslint-disable */
// package: pb
// file: website.proto

var website_pb = require("./website_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var Website = (function () {
  function Website() {}
  Website.serviceName = "pb.Website";
  return Website;
}());

Website.SubscribeNewsletter = {
  methodName: "SubscribeNewsletter",
  service: Website,
  requestStream: false,
  responseStream: false,
  requestType: website_pb.SubscribeRequest,
  responseType: website_pb.ResultResponse
};

Website.WebsiteContact = {
  methodName: "WebsiteContact",
  service: Website,
  requestStream: false,
  responseStream: false,
  requestType: website_pb.ContactRequest,
  responseType: website_pb.ResultResponse
};

exports.Website = Website;

function WebsiteClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

WebsiteClient.prototype.subscribeNewsletter = function subscribeNewsletter(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Website.SubscribeNewsletter, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

WebsiteClient.prototype.websiteContact = function websiteContact(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Website.WebsiteContact, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.WebsiteClient = WebsiteClient;

