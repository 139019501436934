import {
    STATUS_ERROR,
    STATUS_INVALID,
    STATUS_OK,
    STATUS_PROCESSING,
    STATUS_EMPTY_NAME,
    STATUS_EMPTY_MESSAGE,
    STATUS_EMPTY_EMAIL
} from "constants/status";
import { CSRF_HEADER_KEY } from "constants/headers";
import { subscribeNewsletterAction, submitContactFormAction } from "actions/website";
import { SubscribeRequest, ContactRequest } from "generated/website_pb";

const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
function validateEmail(email) {
    return re.test(email);
}

export const subscribeNewsletter = (email) => async (dispatch) => {
    if (!validateEmail(email)) {
        dispatch(subscribeNewsletterAction(STATUS_INVALID, { email: email }));
        return;
    }
    dispatch(subscribeNewsletterAction(STATUS_PROCESSING, { email: email }));

    const req = new SubscribeRequest();
    req.setEmail(email);
    window.api.website.subscribeNewsletter(req, { [CSRF_HEADER_KEY]: window.csrfToken }, (err) => {
        if (err) {
            console.log(err);
            dispatch(subscribeNewsletterAction(STATUS_ERROR, { email: email }));
            return;
        }
        dispatch(subscribeNewsletterAction(STATUS_OK, { email: email }));
    });
};

export const submitContactForm = (name, email, message) => async (dispatch) => {
    const data = { name: name, email: email, message: message };
    const emptyName = name === "";
    const emptyEmail = email === "";
    const emptyMessage = message === "";
    const invalidEmail = !validateEmail(email);

    if (emptyName) {
        dispatch(submitContactFormAction(STATUS_EMPTY_NAME, data));
        return;
    }

    if (emptyEmail) {
        dispatch(submitContactFormAction(STATUS_EMPTY_EMAIL, data));
        return;
    }

    if (emptyMessage) {
        dispatch(submitContactFormAction(STATUS_EMPTY_MESSAGE, data));
        return;
    }

    if (invalidEmail) {
        dispatch(submitContactFormAction(STATUS_INVALID, data));
        return;
    }

    dispatch(submitContactFormAction(STATUS_PROCESSING, data));


    const req = new ContactRequest();
    req.setName(name);
    req.setEmail(email);
    req.setMessage(message);
    window.api.website.websiteContact(req, { [CSRF_HEADER_KEY]: window.csrfToken }, (err) => {
        if (err) {
            console.log(err);
            dispatch(submitContactFormAction(STATUS_ERROR, data));
            return;
        }
        dispatch(submitContactFormAction(STATUS_OK, data));
    });
};
