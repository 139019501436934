import {ACTION_CHANGE_STRINGS_LANGUAGE} from 'constants/actions';

const data = {
    "en": {
        "pages": {
            "footer": {
                "social": {
                    "facebookLink": "https://www.facebook.com/fixpacos",
                    "instagramLink": "",
                    "googlePlusLink": "",
                    "twitterLink": "",
                    "youtubeLink": "https://www.youtube.com/channel/UCS5EbKznno6UhDeRNf0Z7Zw",
                    "linkedInLink": "https://www.linkedin.com/company/fixpaços---materiais-de-fixação"
                }
            },
            "home": {
                "hero": {
                    "title": "PROFESSIONAL FIXING SOLUTIONS",
                    "subtitle": "",
                    "rotatingTextList": ["mechanical fixing", "chemical fixing"]
                },
                "sectionCounters": {
                    "title": "Service of Excellence.",
                    "titleColored": "Guaranteed.",
                    "counters": [
                        {
                            "key": "counter1",
                            "value": 22,
                            "text": "{{.counter}} years of experience"
                        },
                        {
                            "key": "counter2",
                            "value": 4000,
                            "text": "{{.counter}} m<sup>2</sup> of installed capacity"
                        },
                        {
                            "key": "counter3",
                            "value": 24,
                            "text": "Response capability up to {{.counter}} hours"
                        },
                        {
                            "key": "counter4",
                            "value": 20000,
                            "text": "More than {{.counter}} different references"
                        }
                    ]
                }
            },
            "blog": {
                "news": [
                    {
                        "image": "https://storage.googleapis.com/fixpacos/uploads/aniv.jpg",
                        "title": "22ND ANNIVERSARY OF THE FIXPAÇOS",
                        "date": "September 20 2018",
                        "text": "An important day of deep rejoicing and joy, but also of gratitude. Gratitude addressed to all our customers, suppliers and other partners, for the preference and trust deposited in us.\nA striking day that prompts us to recognize the merit and to thank the dedicated commitment of our employees in the sustained growth of the company, which with its work and energy contribute to the success of the collective, for the construction and valorization of A COMPANY ... A TEAM!\nA day that encourages us to reflect on the path we have taken and our positioning.\nA propitious occasion to meditate on the path ahead and on the goals that we can achieve.\nIt is never too much to point out that FIXPAÇOS is the result of hard work and perseverance, whose performance is based on fundamental values ​​such as ethics, trust, commitment and responsibility.\nThis has been the case for the past 22 years and it is intended to continue to be, making the way of the future with a renewed commitment, strengthening team spirit and a common purpose.\n\"All the flowers of the future are in the seeds of today.\"\n\"Positive and happy people remember the past with gratitude, rejoice with the present and face the future without fear.\"\nThe maximum that has been achieved so far is nothing compared to what is still within our reach!\nTo all our customers, collaborators and business partners, THANK YOU!"
                    },
                    {
                        "image": "https://storage.googleapis.com/fixpacos/uploads/lavoro.jpg",
                        "title": "LAVORO CAMPAIGN",
                        "date": "January 05 2018",
                        "text": "Lavoro has signed a protocol with the Portuguese Institute of Oncology - Porto, which will channel financial resources for breast cancer research. The campaign will focus on sales of its Lavoro Woman range.\nThe funds raised will be used to finance research, treatment of breast cancer or support to patients with this pathology, which affects one in eight Portuguese women.\nFIXPAÇOS makes available to different sectors of the industry various materials that guarantee the protection and safety of professionals.\nRegarding protection and safety footwear, we have LAVORO as our commercial partner, so all LAVORO Woman items purchased in our stores will also be considered in this campaign.\nFor each pair of shoes will be offered € 1 to the Portuguese Institute of Oncology - Porto."
                    },
                    {
                        "image": "https://storage.googleapis.com/fixpacos/uploads/pme-e-news.jpg",
                        "title": "PME EXCELLENCE´16",
                        "date": "May 25 2017",
                        "text": "A FIXPAÇOS distinguished with the SME Excellence 2016 status.\nThis is an important distinction that values ​​and honors all those who participate and contribute to the continuous development of the company.\nTo all your partners, employees, customers, suppliers and friends, THANK YOU. This distinction is also yours.\nThe SME Excellence status is awarded by IAPMEI and Turismo de Portugal (in the case of Tourism companies) in partnership with 10 Banks operating in Portugal and also Mutual Guarantee Companies.\nIt is a hallmark of reputation that enables distinguished companies to relate to their surroundings - suppliers, customers, the financial system and national and regional authorities - on a basis of trust that facilitates the development of their business. For exporting companies and with international ambition, the SME Excellence status is particularly relevant, constituting a factor of differentiation and a guarantee of the solidity and suitability of the companies.\nThese companies are selected by IAPMEI and Turismo de Portugal from the universe of the SME Lieder, thus obtaining the SME Excellence status, which aims to give SMEs notoriety, in a fair recognition of their merit and of their contribution to the results of the economy.",
                        "url": "https://www.iapmei.pt/PRODUTOS-E-SERVICOS/Qualificacao-Certificacao/PME-Lider/PME-Excelencia.aspx"
                    }
                ]
            }
        }
    },
    "pt": {
        "pages": {
            "footer": {
                "social": {
                    "facebookLink": "https://www.facebook.com/fixpacos",
                    "instagramLink": "",
                    "googlePlusLink": "",
                    "twitterLink": "",
                    "youtubeLink": "https://www.youtube.com/channel/UCS5EbKznno6UhDeRNf0Z7Zw",
                    "linkedInLink": "https://www.linkedin.com/company/fixpaços---materiais-de-fixação"
                }
            },
            "home": {
                "hero": {
                    "title": "SOLUÇÕES PROFISSIONAIS DE FIXAÇÃO",
                    "subtitle": "Fixação",
                    "rotatingTextList": ["mecânica", "química"]
                },
                "sectionCounters": {
                    "title": "Serviço de Excelência.",
                    "titleColored": "Garantido.",
                    "counters": [
                        {
                            "key": "counter1",
                            "value": 22,
                            "text": "{{.counter}} anos de experiência"
                        },
                        {
                            "key": "counter2",
                            "value": 4000,
                            "text": "{{.counter}} m<sup>2</sup> de capacidade instalada"
                        },
                        {
                            "key": "counter3",
                            "value": 24,
                            "text": "Aptidão de resposta até {{.counter}} horas"
                        },
                        {
                            "key": "counter4",
                            "value": 20000,
                            "text": "Mais de {{.counter}} referências diferentes"
                        }
                    ]
                }
            },
            "blog": {
                "news": [
                    {
                        "image": "https://storage.googleapis.com/fixpacos/uploads/aniv.jpg",
                        "title": "22.º ANIVERSÁRIO DA FIXPAÇOS",
                        "date": "20 Setembro 2018",
                        "text": "Dia importante de profundo regozijo e alegria, mas também de gratidão. Gratidão dirigida a todos os nossos clientes, fornecedores e demais parceiros, pela preferência e confiança depositada em nós.\nDia marcante que nos impele a reconhecer o mérito e a agradecer o empenho dedicado dos nossos colaboradores no crescimento sustentado da empresa, que com o seu trabalho e energia contribuem para o êxito do colectivo, para a construção e valorização de UMA EMPRESA… UMA EQUIPA!\nDia que nos incentiva a reflectir sobre o caminho percorrido e sobre o nosso posicionamento.\nOcasião propícia a meditar sobre o percurso que temos pela frente e sobre as metas que podemos alcançar.\nNunca é demais salientar que a FIXPAÇOS é fruto de muito trabalho e perseverança, cujo desempenho assenta em valores fundamentais como são a ética, a confiança, o compromisso, a responsabilidade.\nFoi assim durante os últimos 22 anos e assim se pretende que continue a ser, fazendo o caminho do futuro com redobrado empenho, fortalecimento do espírito de equipa e um propósito comum.\n“Todas as flores do futuro estão nas sementes de hoje.”\n“As pessoas positivas e felizes lembram o passado com gratidão, alegram-se com o presente e encaram o futuro sem medo.”\nO máximo que já se conseguiu até hoje em nada se compara com o que ainda está ao nosso alcance conseguir!\nA todos os nossos clientes, colaboradores e parceiros de negócios, MUITO OBRIGADO!"
                    },
                    {
                        "image": "https://storage.googleapis.com/fixpacos/uploads/lavoro.jpg",
                        "title": "CAMPANHA LAVORO",
                        "date": "05 Janeiro 2018",
                        "text": "A Lavoro celebrou um protocolo com o IPO – Porto, que permitirá canalizar recursos financeiros para a investigação do cancro da mama. A campanha incidirá sobre as vendas da sua gama Lavoro Woman.\nAs verbas angariadas serão utilizadas para financiar a investigação, o tratamento do cancro da mama ou o apoio a pacientes com esta patologia, que afecta uma em cada oito mulheres portuguesas.\n\nA FIXPAÇOS disponibiliza para diferentes sectores da indústria vários materiais que garantem a proteção e a segurança dos profissionais.\nEm matéria de calçado de proteção e segurança contamos com a LAVORO como parceiro comercial pelo que todos os artigos LAVORO Woman adquiridos nas nossas lojas serão também consideradas nesta campanha.\n\nPor cada par de sapatos será oferecido 1€ ao IPO – Porto."
                    },
                    {
                        "image": "https://storage.googleapis.com/fixpacos/uploads/pme-e-news.jpg",
                        "title": "PME EXCELÊNCIA´16",
                        "date": "25 Maio 2017",
                        "text": "A FIXPAÇOS distinguida com o estatuto PME Excelência 2016.\nEsta é uma importante distinção que valoriza e honra todos aqueles que participam e contribuem para o desenvolvimento contínuo da empresa.\nA todos os seus parceiros, colaboradores, clientes, fornecedores e amigos, MUITO OBRIGADO. Esta distinção também é vossa.\n\nO estatuto PME Excelência é atribuído pelo IAPMEI e pelo Turismo de Portugal (no caso das empresas do Turismo) em parceria com 10 Bancos a operar em Portugal e, ainda, as Sociedades de Garantia Mútua.\nTrata-se de um selo de reputação que permite às empresas distinguidas relacionarem-se com a sua envolvente - fornecedores, clientes, sistema financeiro e autoridades nacionais e regionais - numa base de confiança facilitadora do desenvolvimento dos seus negócios. Para empresas exportadoras e com ambição internacional, o estatuto PME Excelência é particularmente relevante, constituindo um fator de diferenciação e uma garantia da solidez e idoneidade das empresas.\nEstas empresas são selecionadas pelo IAPMEI e pelo Turismo de Portugal a partir do universo das PME Líder, obtendo assim o estatuto PME Excelência que tem como objetivo conferir notoriedade às PME, num justo reconhecimento do seu mérito e do seu contributo para os resultados da economia.",
                        "url": "https://www.iapmei.pt/PRODUTOS-E-SERVICOS/Qualificacao-Certificacao/PME-Lider/PME-Excelencia.aspx"
                    }
                ]
            }
        }
    },
    "fr": {
        "pages": {
            "footer": {
                "social": {
                    "facebookLink": "https://www.facebook.com/fixpacos",
                    "instagramLink": "",
                    "googlePlusLink": "",
                    "twitterLink": "",
                    "youtubeLink": "https://www.youtube.com/channel/UCS5EbKznno6UhDeRNf0Z7Zw",
                    "linkedInLink": "https://www.linkedin.com/company/fixpaços---materiais-de-fixação"
                }
            },
            "home": {
                "hero": {
                    "title": "SOLUTIONS DE FIXATION PROFESSIONNELLES",
                    "subtitle": "fixation",
                    "rotatingTextList": ["mécanique", "chimique"]
                },
                "sectionCounters": {
                    "title": "Service d'excellence.",
                    "titleColored": "Garanti.",
                    "counters": [
                        {
                            "key": "counter1",
                            "value": 22,
                            "text": "{{.counter}} ans d'expérience"
                        },
                        {
                            "key": "counter2",
                            "value": 4000,
                            "text": "{{.counter}} m<sup>2</sup> de capacité installée"
                        },
                        {
                            "key": "counter3",
                            "value": 24,
                            "text": "Capacité de réponse jusqu'à {{.counter}} heures"
                        },
                        {
                            "key": "counter4",
                            "value": 20000,
                            "text": "Plus de {{.counter}} références diferentes"
                        }
                    ]
                }
            },
            "blog": {
                "news": [
                    {
                        "image": "https://storage.googleapis.com/fixpacos/uploads/aniv.jpg",
                        "title": "22ème ANNIVERSAIRE DES FIXPAÇOS",
                        "date": "20 septembre 2018",
                        "text": "Un jour important de joie et de joie profondes, mais aussi de gratitude. Gratitude adressée à tous nos clients, fournisseurs et autres partenaires, pour la préférence et la confiance déposées en nous.\nJour remarquable qui nous oblige à reconnaître le mérite et nous remercions les efforts assidus de nos employés à la croissance soutenue de l'entreprise, qui, avec leur travail et de l'énergie contribuent à la réussite du collectif, pour la construction et la mise en valeur d'une entreprise ... Une équipe!\nUne journée qui nous encourage à réfléchir au chemin parcouru et à notre positionnement.\nUne occasion propice pour méditer sur la voie à suivre et sur les objectifs que nous pouvons atteindre.\nIl n'est jamais trop important de souligner que FIXPAÇOS est le résultat d'un travail acharné et de la persévérance, dont les performances reposent sur des valeurs fondamentales telles que l'éthique, la confiance, l'engagement et la responsabilité.\nCela a été le cas au cours des 22 dernières années et il est prévu que cela continue d’être la voie de l’avenir avec un engagement renouvelé, un esprit d’équipe et un objectif commun. “Toutes les fleurs du futur sont dans les graines d'aujourd'hui.”\n“Les gens positifs et heureux se souviennent du passé avec gratitude, réjouissez-vous avec le présent et affrontez l'avenir sans crainte.”\nLe maximum atteint jusqu'à présent n'est rien comparé à ce qui est encore à notre portée!\nÀ tous nos clients, collaborateurs et partenaires commerciaux, MERCI!"
                    },
                    {
                        "image": "https://storage.googleapis.com/fixpacos/uploads/lavoro.jpg",
                        "title": "ENSEMBLE CONTRE LE CANCER DU SEIN",
                        "date": "05 janvier 2018",
                        "text": "Lavoro a signé un protocole avec l' Institut portugais d'oncologie - Porto, qui canalisera les ressources financières pour la recherche sur le cancer du sein. La campagne se concentrera sur les ventes de sa gamme Lavoro Woman.\nLes fonds recueillis serviront à financer la recherche, le traitement du cancer du sein ou le soutien aux patients atteints de cette pathologie, qui touche une femme portugaise sur huit.\nFIXPAÇOS met à la disposition de différents secteurs de l’industrie différents matériaux qui garantissent la protection et la sécurité des professionnels.\nEn ce qui concerne les chaussures de protection et de sécurité, nous avons LAVORO comme partenaire commercial, de sorte que tous les articles achetés dans nos magasins LAVORO Woman seront également pris en compte dans cette campagne.\nPour chaque paire de chaussures sera offert 1 € à l' Institut portugais d'oncologie - Porto."
                    },
                    {
                        "image": "https://storage.googleapis.com/fixpacos/uploads/pme-e-news.jpg",
                        "title": "EXCELLENCE PME '16",
                        "date": "25 mai 2017",
                        "text": "Un FIXPAÇOS distingué avec le statut PME Excellence 2016.\nC'est une distinction importante qui valorise et honore tous ceux qui participent et contribuent au développement continu de l'entreprise.\nÀ tous vos partenaires, employés, clients, fournisseurs et amis, MERCI. Cette distinction est aussi la vôtre.\nLe statut PME Excellence est attribué par IAPMEI et Turismo de Portugal (dans le cas des entreprises touristiques) en partenariat avec 10 banques opérant au Portugal et également des sociétés de garantie mutuelle.\nIl est un sceau de bonne réputation qui permet aux entreprises distinguées se rapporter à son environnement - les fournisseurs, les clients, le système financier et les autorités nationales et régionales - sur la base de la confiance en facilitant le développement de leurs activités. Pour l'exportation et les entreprises d'ambition internationale, le statut de PME Excellence est particulièrement pertinent, offrant un facteur de différenciation et d'une garantie de solidité et de fiabilité des entreprises.\nCes entreprises sont sélectionnées par IAPMEI et par Turismo de Portugal de l'univers des PME Leader, obtenant ainsi le statut de PME Excellence qui vise à donner une visibilité aux PME, une juste reconnaissance de leurs mérites et de leur contribution aux résultats de l'économie.",
                        "url": "https://www.iapmei.pt/PRODUTOS-E-SERVICOS/Qualificacao-Certificacao/PME-Lider/PME-Excelencia.aspx"
                    }
                ]
            }
        }
    }
}

const stringsReducer = (state = {}, action) => {
    switch (action.type) {
        case ACTION_CHANGE_STRINGS_LANGUAGE:
            return {...state, ...data[action.payload.lang]};
        default:
            return state;
    }
};

export default stringsReducer;