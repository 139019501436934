import React, {Component} from 'react';
import {Flex, Heading, Divider, Container, Text, Link, Box} from 'rebass';
import {Translate} from "react-localize-redux";
import posed from 'react-pose';
import {Icon} from "react-icons-kit";
import {book_1, book_2} from 'react-icons-kit/ikons/'
import {Helmet} from "react-helmet";

import TitleBox from 'components/TitleBox';

import 'pages/Catalogues/Catalogues.css';


const AnimatedBox = posed.div({
    hoverable: true,
    pressable: true,
    init: {
        scale: 1,
        boxShadow: '0px 0px 0px rgba(0,0,0,0)'
    },
    hover: {
        scale: 1.2,
        boxShadow: '0px 5px 10px rgba(0,0,0,0.2)'
    },
    press: {
        scale: 1.1,
        boxShadow: '0px 2px 5px rgba(0,0,0,0.1)'
    }
});

class Catalogues extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibility: "hide"
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({visibility: "show"})
    }

    render() {
        const {activeLanguage, translate} = this.props;
        return (
            <Flex flexDirection="column" alignItems="center" bg='#FAFAFA' style={{overflow: "hidden"}}>
                <Helmet>
                    <title>{translate("pageTitle") + " - " + translate("catalogues")}</title>
                    <meta name="description" content={translate("cataloguesNote1")}/>
                    <link rel="canonical" href={window.pageURL + this.props.location.pathname}/>
                    <link rel="alternate" hrefLang="pt" href={window.pageURL + "/pt" + this.props.location.pathname}/>
                    <link rel="alternate" hrefLang="en" href={window.pageURL + "/en" + this.props.location.pathname}/>
                    <link rel="alternate" hrefLang="fr" href={window.pageURL + "/fr" + this.props.location.pathname}/>
                    <link rel="alternate" hrefLang="x-default" href={window.pageURL + this.props.location.pathname}/>
                </Helmet>
                <Box className="header"/>
                <Flex width="80%" mt={4} mb={4}>
                    <TitleBox pose={this.state.visibility}>
                        <Container>
                            <Heading color="#424242" fontSize={["5vmax", "4vmax", "3vmax"]} textAlign="left">
                                <Translate id="catalogues"/></Heading>
                            <Divider borderColor='#98C221'/>
                        </Container>
                    </TitleBox>
                </Flex>
                <Box my={4} mx={2} width={["80%", "60%"]}>
                    <Text fontSize="1.3em" textAlign="center" color="#424242"><Translate id="cataloguesNote1"/></Text>
                </Box>
                <Flex justifyContent="center" width="100%" flexWrap='wrap' mt={20}>
                    <Link
                        href={"https://storage.googleapis.com/fixpacos/catalogo-" + (activeLanguage !== undefined ? activeLanguage.code : "pt") + ".pdf"}
                        target="_blank"
                        style={{textDecoration: "none"}}>
                        <AnimatedBox className="animated-box">
                            <Flex m={2} flexDirection="column" justifyContent="space-between" alignItems="stretch"
                                  style={{height: "95%"}}>
                                <Heading color="#FAFAFA" mt={10}><Translate id="catalogue"/></Heading>
                                <Box alignSelf="center" color="#FAFAFA"><Icon size='100' icon={book_2}/></Box>
                                <Text color="#FAFAFA" textAlign='center'><Translate id="clickToDownload"/></Text>
                            </Flex>
                        </AnimatedBox>
                    </Link>
                    <Link
                        href={"https://storage.googleapis.com/fixpacos/revista-" + (activeLanguage !== undefined ? activeLanguage.code : "pt") + ".pdf"}
                        target="_blank"
                        style={{textDecoration: "none"}}>
                        <AnimatedBox className="animated-box">
                            <Flex m={2} flexDirection="column" justifyContent="space-between" alignItems="stretch"
                                  style={{height: "95%"}}>
                                <Heading color="#FAFAFA" mt={10}><Translate id="magazine"/></Heading>
                                <Box alignSelf="center" color="#FAFAFA"><Icon size='100' icon={book_1}/></Box>
                                <Text color="#FAFAFA" textAlign='center'><Translate id="clickToDownload"/></Text>
                            </Flex>
                        </AnimatedBox>
                    </Link>
                    <Link
                        href={"https://storage.googleapis.com/fixpacos/campanhas-" + (activeLanguage !== undefined ? activeLanguage.code : "pt") + ".pdf"}
                        target="_blank"
                        style={{textDecoration: "none"}}>
                        <AnimatedBox className="animated-box">
                            <Flex m={2} flexDirection="column" justifyContent="space-between" alignItems="stretch"
                                  style={{height: "95%"}}>
                                <Heading color="#FAFAFA" mt={10}><Translate id="campaigns"/></Heading>
                                <Box alignSelf="center" color="#FAFAFA"><Icon size='100' icon={book_1}/></Box>
                                <Text color="#FAFAFA" textAlign='center'><Translate id="clickToDownload"/></Text>
                            </Flex>
                        </AnimatedBox>
                    </Link>
                </Flex>
                <Box my={4} mx={2}>
                    <Text fontSize="1em" textAlign="center" color="#424242"><Translate id="cataloguesNote2"/></Text>
                </Box>
            </Flex>
        );
    }
}

export default Catalogues;