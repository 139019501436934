import React from "react";
import PropTypes from 'prop-types';
import {Box, Divider, Flex, Heading, Subhead} from "rebass";
import RotatingText from "react-rotating-text";
import {ScrollDownIndicator, Hero as RebassHero} from "react-landing-page";

import background from "images/hero-background.jpg";


const Hero = ({title, subtitle, rotatingTextList}) => {
    return (
        <RebassHero
            backgroundImage={background}>
            <Flex alignItems='start'>
                <Box width={[1, 1, 1/2]} ml={["10%","10%", "0%"]} mr={["10%","10%", "0%"]}>
                    <Heading color="white" fontSize="4vmax" textAlign={['center', 'center', 'left', 'left']}>{title}</Heading>
                    <Divider w={1} borderColor='#98C221'/>
                    <Subhead color="#98C221"  fontSize={["4vmax", "4vmax", "3vmax", "2.5vmax"]} textAlign={['center', 'center', 'left', 'left']}>
                        {subtitle+" "}<RotatingText items={rotatingTextList}/>
                    </Subhead>
                </Box>
            </Flex>
            <ScrollDownIndicator onClick={() => window.scroll({ top: window.outerHeight-100, left: 0, behavior: 'smooth' })}/>
        </RebassHero>
    );
}

Hero.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    rotatingTextList: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Hero;