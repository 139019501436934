import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import 'babel-polyfill';
import 'bootstrap/dist/css/bootstrap.min.css';
import registerServiceWorker from 'registerServiceWorker';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { renderToStaticMarkup } from "react-dom/server";
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { offline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import { LocalizeProvider, withLocalize, initialize, addTranslationForLanguage } from "react-localize-redux";

import NotFound from 'pages/NotFound';
import AppReducers from 'reducers';
import Header from 'components/Header';
import Footer from 'components/Footer';
import pagesRoutes from 'pagesRoutes';
import translations from 'translations';
import { changeStringsLanguage } from 'actions/strings';

import { grpc } from "grpc-web-client";
import { WebsiteClient } from "generated/website_pb_service";
import { CSRF_HEADER_KEY } from "constants/headers";

import 'index.css';

const API_URL = "/api/v1";

// get csrf token
fetch(API_URL, {
    method: 'GET',
}).then(response => window.csrfToken = response.headers.get(CSRF_HEADER_KEY));

const DefaultGRPCTransport = grpc.CrossBrowserHttpTransport({ withCredentials: true });
grpc.setDefaultTransport(DefaultGRPCTransport);

window.api = {
    website: new WebsiteClient(API_URL)
};

const config = {
    ...offlineConfig,
    persistOptions: {
        blacklist: ['localize'],
    }
};

const store = createStore(AppReducers, applyMiddleware(thunk), offline(config));

let languages = [];
translations.forEach(function (tr) {
    languages.push(tr.lang)
});

let defaultLanguage = window.location.pathname.split("/")[1];
let basename = "";
if (languages.includes(defaultLanguage)) {
    basename = defaultLanguage;
    localStorage.setItem("languageCode", defaultLanguage);
} else {
    defaultLanguage = localStorage.getItem("languageCode") || (navigator.language || languages[0].lang).substr(0, 2);
}
store.dispatch(initialize({ languages: languages, options: { renderToStaticMarkup, defaultLanguage } }));
store.dispatch(changeStringsLanguage(defaultLanguage));
translations.forEach(function (tr) {
    store.dispatch(addTranslationForLanguage(tr.translations, tr.lang));
});

export class App extends Component {
    render() {
        return (
            <Router basename={basename}>
                <Provider store={store}>
                    <LocalizeProvider store={store}>
                        <Header menuItems={pagesRoutes} />
                        <Switch>
                            {pagesRoutes.map(route => (
                                <Route key={route.key} exact={route.path === '/'} path={route.path}
                                    component={withLocalize(route.component)} />
                            ))}
                            <Route component={withLocalize(NotFound)} />
                        </Switch>
                        <Footer />
                    </LocalizeProvider>
                </Provider>
            </Router>
        );
    }
}

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
