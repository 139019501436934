import React from 'react';
import {connect} from "react-redux";
import {Provider, Heading, Flex, Box, Text, Image, Link, Card, Container, Subhead, Small} from 'rebass';
import {Section} from 'react-landing-page';
import {Translate} from "react-localize-redux";
import VisibilitySensor from 'react-visibility-sensor';

import Hero from 'components/Hero';
import Counter from 'components/Counter'
import {Player, LoadingSpinner} from 'video-react';
import "video-react/dist/video-react.css";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';

import posed from 'react-pose';

import videoPoster from 'images/video-poster.jpg';
import videoImagePlay from 'images/video-poster-play.jpg';

import screwsImage from 'images/screws.png';
import bushingImage from 'images/bushings.png';
import chemicalsImage from 'images/chemicals.png';
import chainsImage from 'images/chains.png';
import accessoriesImage from 'images/accessories.png';
import protectionImage from 'images/protection.png';
import handToolsImage from 'images/handtools.png';
import laserImage from 'images/laser.png';

import 'pages/HomePage/HomePage.css';
import {Helmet} from "react-helmet";

const FromRightBox = posed.div({
    show: {
        x: 0,
        opacity: 1
    },
    hide: {
        x: window.outerWidth,
        opacity: 0
    }
});

const FromLeftBox = posed.div({
    show: {x: 0, opacity: 1},
    hide: {x: -window.outerWidth, opacity: 0}
});

const ZoomBox = posed.div({
    hide: {
        scale: 0.1,
        opacity: 0
    },
    show: {
        scale: 1,
        opacity: 1
    }
})

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        var indexes = [];
        for (var i = 0; i < props.news.length; i++) {
            indexes.push(false);
        }
        this.state = {
            videoTextAnimated: false,
            videoAnimated: false,
            showVideo: false,
            featuresTitleAnimated: false,
            feature1Animated: false,
            feature2Animated: false,
            feature3Animated: false,
            feature4Animated: false,
            feature5Animated: false,
            feature6Animated: false,
            feature7Animated: false,
            feature8Animated: false,
            newsTitleAnimated: false,
            newsIndexes: indexes
        }
        this.toggleNewsRodal = this.toggleNewsRodal.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    toggleNewsRodal(index) {
        const newsIndexes = this.state.newsIndexes;
        newsIndexes[index] = !newsIndexes[index];
        this.setState({newsIndexes});
    }

    render() {
        const {strings, translate, news} = this.props;
        return (
            <Provider>
                <Helmet>
                    <title>{translate("pageTitle")}</title>
                    <meta name="description" content={translate("homePageDescription")}/>
                    <link rel="canonical" href={window.pageURL}/>
                    <link rel="alternate" hrefLang="pt" href={window.pageURL + "/pt"}/>
                    <link rel="alternate" hrefLang="en" href={window.pageURL + "/en"}/>
                    <link rel="alternate" hrefLang="fr" href={window.pageURL + "/fr"}/>
                    <link rel="alternate" hrefLang="x-default" href={window.pageURL}/>
                </Helmet>
                <Hero title={strings.pages.home.hero.title} subtitle={strings.pages.home.hero.subtitle}
                      rotatingTextList={strings.pages.home.hero.rotatingTextList}/>
                <Section bg='#212121'>
                    <Flex flexDirection={["column", "row", "row", "row"]}>
                        <Heading color="white" fontSize="3vmax"
                                 textAlign="center">{strings.pages.home.sectionCounters.title}</Heading>
                        <Heading color="#98C221" fontSize="3vmax" textAlign="center"
                                 ml={3}>{strings.pages.home.sectionCounters.titleColored}</Heading>
                    </Flex>
                    <Flex flexWrap='wrap' alignItems='flex-start' justifyContent='center' pt={50}>
                        {strings.pages.home.sectionCounters.counters.map((counter) => (
                            <Box key={counter.key} width={[1, 1 / 2]} p={10} flex={1}>
                                <Counter stopAt={counter.value} text={counter.text}/>
                            </Box>
                        ))}
                    </Flex>
                </Section>
                <Flex flexDirection="column" alignItems="center" justifyContent="center" className="center-section"
                      style={{overflow: "hidden"}}>
                    <Flex flexWrap='wrap' alignItems='center' justifyContent="center" width="90%"
                          className="video-section" my={6} bg="#FAFAFA">
                        <Flex alignItems='flex-start' justifyContent="center" width={[1, 1, 1, 1 / 2]} p={3}>
                            <VisibilitySensor
                                onChange={(isVisible) => isVisible && !this.state.videoAnimated && this.setState({
                                    videoAnimated: true,
                                    videoTextAnimated: true
                                })}>
                                <Box width="100%">
                                    <ZoomBox pose={this.state.videoAnimated ? "show" : "hide"}>
                                        <Link href="#!" onClick={() => {
                                            this.setState({showVideo: true});
                                            this.refs.videoPlayer.play();
                                        }}><Image src={videoImagePlay} className="video-image"/></Link>
                                        <Rodal width={1000} visible={this.state.showVideo}
                                               onClose={() => {
                                                   this.setState({showVideo: false});
                                                   this.refs.videoPlayer.pause();
                                               }} animation="fade" duration={500}>
                                            <Box width="100%">
                                                <Player
                                                    ref="videoPlayer"
                                                    poster={videoPoster}
                                                    playsInline
                                                    src="https://storage.googleapis.com/fixpacos/fixpacos2018.mp4">
                                                    <LoadingSpinner/>
                                                </Player>
                                            </Box>
                                        </Rodal>
                                    </ZoomBox>
                                </Box>
                            </VisibilitySensor>
                        </Flex>
                        <Flex width={[1, 1, 1, 1 / 2]} alignItems='center' justifyContent="center"
                              flexDirection='column' p={[0, 1, 3, 3]}>
                            <FromRightBox pose={this.state.videoTextAnimated ? "show" : "hide"}>
                                <Box m={5}>
                                    <Heading fontSize={["2em", "2em", "2em", "3em"]} color="#424242"><Translate
                                        id="videoTitle"/></Heading>
                                    <Heading fontSize={["2em", "2em", "2em", "3em"]} color="#98C221"><Translate
                                        id="videoSubtitle"/></Heading>
                                    <Text fontSize={["1.4em", "1.4em", "1.4em", "1.6em"]} mt={3} color="#424242"
                                          children={translate("videoText")}/>
                                </Box>
                            </FromRightBox>
                        </Flex>
                    </Flex>
                    <Flex flexDirection="column" alignItems="center" justifyContent="center" bg="#98C221">
                        <VisibilitySensor
                            onChange={(isVisible) => isVisible && !this.state.featuresTitleAnimated && this.setState({
                                featuresTitleAnimated: true,
                            })}>
                            <Flex>
                                <FromLeftBox pose={this.state.featuresTitleAnimated ? "show" : "hide"}>
                                    <Heading textAlign="center" mt={6} fontSize={["2em", "2em", "2em", "3em"]}
                                             color="#212121">
                                        <Translate id="qualityProducts"/>
                                        <span style={{color: "#FAFAFA", marginLeft: "6px"}}><Translate
                                            id="qualityProductsSubtitle"/></span>
                                    </Heading>
                                </FromLeftBox>
                            </Flex>
                        </VisibilitySensor>
                        <Flex flexWrap="wrap" justifyContent="center" my={5} width="90%" color="#212121">
                            <Flex width={[1, 1 / 2, 1 / 3, 1 / 4]} flexWrap="wrap" alignItems="center">
                                <VisibilitySensor
                                    onChange={(isVisible) => isVisible && !this.state.feature1Animated && this.setState({
                                        feature1Animated: true,
                                    })}>
                                    <ZoomBox pose={this.state.feature1Animated ? "show" : "hide"}>
                                        <Flex flexDirection="column" alignItems="center" mb={4} className="feature">
                                            <Image src={screwsImage} className="feature-image"/>
                                            <Heading className="feature-title" fontSize="1.3em" textAlign="center"
                                                     children={translate("feature1Title")} mt={4}/>
                                            <Text className="feature-text" color="#212121" textAlign="center"
                                                  children={translate("feature1Text")} mx={3}/>
                                        </Flex>
                                    </ZoomBox>
                                </VisibilitySensor>
                            </Flex>
                            <Flex width={[1, 1 / 2, 1 / 3, 1 / 4]} flexWrap="wrap" alignItems="center">
                                <VisibilitySensor
                                    onChange={(isVisible) => isVisible && !this.state.feature2Animated && this.setState({
                                        feature2Animated: true,
                                    })}>
                                    <ZoomBox pose={this.state.feature2Animated ? "show" : "hide"}>
                                        <Flex flexDirection="column" alignItems="center" mb={4} className="feature">
                                            <Image src={bushingImage} className="feature-image"/>
                                            <Heading className="feature-title" fontSize="1.3em" textAlign="center"
                                                     children={translate("feature2Title")} mt={4}/>
                                            <Text className="feature-text" color="#212121" textAlign="center"
                                                  children={translate("feature2Text")} mx={3}/>
                                        </Flex>
                                    </ZoomBox>
                                </VisibilitySensor>
                            </Flex>
                            <Flex width={[1, 1 / 2, 1 / 3, 1 / 4]} flexWrap="wrap" alignItems="center">
                                <VisibilitySensor
                                    onChange={(isVisible) => isVisible && !this.state.feature3Animated && this.setState({
                                        feature3Animated: true,
                                    })}>
                                    <ZoomBox pose={this.state.feature3Animated ? "show" : "hide"}>
                                        <Flex flexDirection="column" alignItems="center" mb={4} className="feature">
                                            <Image src={chemicalsImage} className="feature-image"/>
                                            <Heading className="feature-title" fontSize="1.3em" textAlign="center"
                                                     children={translate("feature3Title")} mt={4}/>
                                            <Text className="feature-text" color="#212121" textAlign="center"
                                                  children={translate("feature3Text")} mx={3}/>
                                        </Flex>
                                    </ZoomBox>
                                </VisibilitySensor>
                            </Flex>
                            <Flex width={[1, 1 / 2, 1 / 3, 1 / 4]} flexWrap="wrap" alignItems="center">
                                <VisibilitySensor
                                    onChange={(isVisible) => isVisible && !this.state.feature4Animated && this.setState({
                                        feature4Animated: true,
                                    })}>
                                    <ZoomBox pose={this.state.feature4Animated ? "show" : "hide"}>
                                        <Flex flexDirection="column" alignItems="center" mb={4} className="feature">
                                            <Image src={chainsImage} className="feature-image"/>
                                            <Heading className="feature-title" fontSize="1.3em" textAlign="center"
                                                     children={translate("feature4Title")} mt={4}/>
                                            <Text className="feature-text" color="#212121" textAlign="center"
                                                  children={translate("feature4Text")} mx={3}/>
                                        </Flex>
                                    </ZoomBox>
                                </VisibilitySensor>
                            </Flex>
                            <Flex width={[1, 1 / 2, 1 / 3, 1 / 4]} flexWrap="wrap" alignItems="center">
                                <VisibilitySensor
                                    onChange={(isVisible) => isVisible && !this.state.feature5Animated && this.setState({
                                        feature5Animated: true,
                                    })}>
                                    <ZoomBox pose={this.state.feature5Animated ? "show" : "hide"}>
                                        <Flex flexDirection="column" alignItems="center" mb={4} className="feature">
                                            <Image src={accessoriesImage} className="feature-image"/>
                                            <Heading className="feature-title" fontSize="1.3em" textAlign="center"
                                                     children={translate("feature5Title")} mt={4}/>
                                            <Text className="feature-text" color="#212121" textAlign="center"
                                                  children={translate("feature5Text")} mx={3}/>
                                        </Flex>
                                    </ZoomBox>
                                </VisibilitySensor>
                            </Flex>
                            <Flex width={[1, 1 / 2, 1 / 3, 1 / 4]} flexWrap="wrap" alignItems="center">
                                <VisibilitySensor
                                    onChange={(isVisible) => isVisible && !this.state.feature6Animated && this.setState({
                                        feature6Animated: true,
                                    })}>
                                    <ZoomBox pose={this.state.feature6Animated ? "show" : "hide"}>
                                        <Flex flexDirection="column" alignItems="center" mb={4} className="feature">
                                            <Image src={protectionImage} className="feature-image"/>
                                            <Heading className="feature-title" fontSize="1.3em" textAlign="center"
                                                     children={translate("feature6Title")} mt={4}/>
                                            <Text className="feature-text" color="#212121" textAlign="center"
                                                  children={translate("feature6Text")} mx={3}/>
                                        </Flex>
                                    </ZoomBox>
                                </VisibilitySensor>
                            </Flex>
                            <Flex width={[1, 1 / 2, 1 / 3, 1 / 4]} flexWrap="wrap" alignItems="center">
                                <VisibilitySensor
                                    onChange={(isVisible) => isVisible && !this.state.feature7Animated && this.setState({
                                        feature7Animated: true,
                                    })}>
                                    <ZoomBox pose={this.state.feature7Animated ? "show" : "hide"}>
                                        <Flex flexDirection="column" alignItems="center" mb={4} className="feature">
                                            <Image src={handToolsImage} className="feature-image"/>
                                            <Heading className="feature-title" fontSize="1.3em" textAlign="center"
                                                     children={translate("feature7Title")} mt={4}/>
                                            <Text className="feature-text" color="#212121" textAlign="center"
                                                  children={translate("feature7Text")} mx={3}/>
                                        </Flex>
                                    </ZoomBox>
                                </VisibilitySensor>
                            </Flex>
                            <Flex width={[1, 1 / 2, 1 / 3, 1 / 4]} flexWrap="wrap" alignItems="center">
                                <VisibilitySensor
                                    onChange={(isVisible) => isVisible && !this.state.feature8Animated && this.setState({
                                        feature8Animated: true,
                                    })}>
                                    <ZoomBox pose={this.state.feature8Animated ? "show" : "hide"}>
                                        <Flex flexDirection="column" alignItems="center" mb={4} className="feature">
                                            <Image src={laserImage} className="feature-image"/>
                                            <Heading className="feature-title" fontSize="1.3em" textAlign="center"
                                                     children={translate("feature8Title")} mt={4}/>
                                            <Text className="feature-text" color="#212121" textAlign="center"
                                                  children={translate("feature8Text")} mx={3}/>
                                        </Flex>
                                    </ZoomBox>
                                </VisibilitySensor>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex alignItems="center" flexDirection="column" justifyContent="center" bg="#FAFAFA"
                      style={{overflow: "hidden"}}>
                    <VisibilitySensor
                        onChange={(isVisible) => isVisible && !this.state.newsTitleAnimated && this.setState({
                            newsTitleAnimated: true,
                        })}>
                        <Flex>
                            <FromLeftBox pose={this.state.newsTitleAnimated ? "show" : "hide"}>
                                <Heading textAlign="center" mt={6} mb={4} fontSize={["2em", "2em", "2em", "3em"]}
                                         color="#212121">
                                    <Translate id="newsTitle"/>
                                </Heading>
                            </FromLeftBox>
                        </Flex>
                    </VisibilitySensor>
                </Flex>
                <Flex flexDirection={["column", "row"]} alignItems="center" justifyContent="center" bg="#FAFAFA"
                      flexWrap="wrap" mb={4} style={{overflow: "hidden"}}>
                    {news.map((item, i) =>
                        <Card key={i} borderRadius={6} boxShadow={5} bg="#eaeaea" m={4}
                              width={["250px", "250px", "300px", "350px"]} className="news-card">
                            <Link href="#!" onClick={() => this.toggleNewsRodal(i)} className="news-card-link">
                                <Flex flexDirection="column" mx={3} style={{height: "100%"}} alignItems="flex-end"
                                      justifyContent="space-between">
                                    <Box width="100%" mt={3}>
                                        <Text fontWeight="bold" fontSize="1.2em" textAlign="center"
                                              children={item.title}/>
                                    </Box>
                                    <Box p={3} width="100%">
                                        <Image src={item.image}/>
                                    </Box>
                                    <Box width="100%" mb={3}>
                                        <Text key={i} mt={3}
                                              dangerouslySetInnerHTML={{__html: item.text.replace(/\\n+/g, " ").split(' ').slice(0, 20).join(" ") + '...'}}/>
                                    </Box>
                                </Flex>
                            </Link>
                            <Rodal width={1000} visible={this.state.newsIndexes[i]}
                                   onClose={() => this.toggleNewsRodal(i)} animation="rotate" duration={500}>
                                <Container>
                                    <Subhead children={item.title}/>
                                    <Flex flexDirection={["column", "row"]} my={3}>
                                        <Box width={[1, 1, 1 / 2, 1 / 2]} p={3}>
                                            <Image src={item.image}/>
                                        </Box>
                                        <Box width={[1, 1, 1 / 2, 1 / 2]}>
                                            {item.text.split("\n").map((p, i) => (
                                                <Text key={i} mt={3} dangerouslySetInnerHTML={{__html: p}}/>
                                            ))}
                                            {item.url !== undefined &&
                                            <Link href={item.url} target="_blank" mt={6}>
                                                <Text textAlign="right" children={translate("checkSource")}/>
                                            </Link>
                                            }
                                        </Box>
                                    </Flex>
                                    <Small>{item.date}</Small>
                                </Container>
                            </Rodal>
                        </Card>
                    )}
                </Flex>
            </Provider>
        );
    }
}

const mapStateToProps = state => {
    return {
        strings: state.strings,
        news: state.strings.pages.blog.news.slice(0, 4),
    };
};

export default connect(mapStateToProps)(HomePage);